import React from 'react';
import './landing.css';
import logo from './assets/kindkukilogo.png';
import gif from './assets/pixelkuki.gif';

const LandingPage: React.FC = () => {
  return (
    <div className="landing-page">
      <div className="centered-content">
        <img src={logo} alt="Logo" className="logo" />
        <img src={gif} alt="Loading animation" className="gif" />
        <p>O site está sendo gerado, tente novamente mais tarde.</p>
      </div>
    </div>
  );
};

export default LandingPage;
