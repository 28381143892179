import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface IconLinkProps {
  to: string;
  imgSrcStatic: string;
  imgSrcAnimated: string;
  alt: string;
  position: {
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
  };
}

const IconContainer = styled.div<{ position: IconLinkProps['position'] }>`
  position: absolute;
  width: 200px; /* Define largura aumentada */
  height: 200px; /* Define altura aumentada */
  ${(props) => props.position && { ...props.position }}
`;

const Icon = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;

  @media (max-width: 768px) {
    display: none; /* Torna o ícone invisível no modo mobile */
  }
`;

const StaticIcon = styled(Icon)`
  z-index: 2;
  opacity: 1;

  ${IconContainer}:hover & {
    opacity: 0;
  }
`;

const AnimatedIcon = styled(Icon)`
  z-index: 1;
  opacity: 0;

  ${IconContainer}:hover & {
    opacity: 1;
  }
`;

const IconLink: React.FC<IconLinkProps> = ({ to, imgSrcStatic, imgSrcAnimated, alt, position }) => {
  return (
    <Link to={to}>
      <IconContainer position={position}>
        <StaticIcon src={imgSrcStatic} alt={alt} />
        <AnimatedIcon src={imgSrcAnimated} alt={alt} />
      </IconContainer>
    </Link>
  );
};

export default IconLink;
