import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/home';
import Collections from './pages/collections';
import Blog from './pages/blog';
import Shop from './pages/shop';
import Kuki from './pages/kuki';
import AninhaBraun from './pages/aninhaBraun';


const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/collections" element={<Collections />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/kuki" element={<Kuki />} />
        <Route path="/aninha-braun" element={<AninhaBraun />} />
      </Routes>
    </Router>
  );
};

export default App;
