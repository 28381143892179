import React from 'react';
import LandingPage from '../loading/landing';

const Kuki: React.FC = () => {
  return (
    <LandingPage />
  );
};

export default Kuki;
