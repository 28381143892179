import React from 'react';
import LandingPage from '../loading/landing';

const Shop: React.FC = () => {
  return (
    <LandingPage />
  );
};

export default Shop;
