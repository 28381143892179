import { Link } from "react-router-dom";
import { styled } from "styled-components";

const Header = styled.header`
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  width: calc(100% - 20px);
  justify-content: space-between;
  z-index: 10
`;

const Logo = styled.img`
  height: 100;
  width: 100%;
`;

const LogoContainer = styled(Link)`
  width: 20%;
  height: 5%;
`

const HeaderIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding-right: 20px;
`;

const SocialMedia = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HomeHeader : React.FC = () => {

    return(

    <Header>
        <LogoContainer to = '/'><Logo src='/icons/kindkukilogo.png' alt="KINDKUKI" /></LogoContainer>
        <HeaderIcons>
          <SocialMedia href="https://www.instagram.com/kindkuki/" target="_blank" rel="noopener noreferrer">
            <img src="/icons/insta.svg" alt="Instagram" width="30" height="30" />
          </SocialMedia>
          <SocialMedia href="https://www.youtube.com/@KINDKUKI" target="_blank" rel="noopener noreferrer">
            <img src="/icons/yt.svg" alt="YouTube" width="30" height="30" />
          </SocialMedia>
          <SocialMedia href="https://x.com/kindkuki" target="_blank" rel="noopener noreferrer">
            <img src="/icons/twitter.svg" alt="Twitter" width="30" height="30" color="white"/>
          </SocialMedia>
        </HeaderIcons>
      </Header>

    );

}

export default HomeHeader;