import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import IconLink from '../../components/icon/home';
import HomeHeader from '../../components/headers/home';

const Container = styled.div`
  background-image: url('/images/background.jpg');
  background-size: 100% 100%;
  background-repeat: 'no-repeat';
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const IconsMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  position: relative;

  @media (min-width: 768px) {
    position: relative;
  }
`;

const TextLink = styled(Link)`
  position: absolute;
  color: white;
  font-size: 3em;
  font-family: 'Roboto', serif;
  text-decoration: none;
  

  @media (max-width: 768px) {
    position: static;
    margin: 10px 0;
    text-align: center;
  }
`;

const HomePage: React.FC = () => {
  return (
    <Container>
      <HomeHeader />
      <IconsMenu>
        <IconLink
          to="/collections"
          imgSrcStatic='/images/collections.png'
          imgSrcAnimated="/gifs/collections.gif"
          alt="Collections"
          position={{ top: '20%', left: '30%' }}
        />
        <TextLink to={"/collections"} style={{ top: '15%', left: '35%' }}>Collections</TextLink>

        <IconLink
          to="/blog"
          imgSrcStatic='/images/pixelplanet.png'
          imgSrcAnimated="/gifs/pixelplanet.gif"
          alt="Blog"
          position={{ top: '20%', right: '20%' }}
        />
        <TextLink to={"/blog"} style={{ top: '45%', right: '15%' }}>Blog</TextLink>

        <IconLink
          to="/shop"
          imgSrcStatic='/images/shop.png'
          imgSrcAnimated="/gifs/shop.gif"
          alt="Shop"
          position={{ bottom: '20%', left: '10%' }}
        />
        <TextLink to={"/shop"} style={{ bottom: '10%', left: '15%'  }}>Shop</TextLink>

        <IconLink
          to="/kuki"
          imgSrcStatic='/images/pixelkuki.png'
          imgSrcAnimated="/gifs/pixelkuki.gif"
          alt="Kuki"
          position={{ bottom: '5%', left: '40%' }}
        />
        <TextLink to={"/kuki"} style={{ bottom: '0%', left: '35%' }}>Kuki</TextLink>

        <IconLink
          to="/aninha-braun"
          imgSrcStatic='/images/aninha.png'
          imgSrcAnimated="/gifs/aninha.gif"
          alt="Aninha Braun"
          position={{ top: '60%', right: '10%' }}
        />
        <TextLink to={"/aninha-braun"} style={{ top: '70%', right: '25%' }}>Aninha< br/>Braun</TextLink>
      </IconsMenu>
    </Container>
  );
};

export default HomePage;
